import React from "react"
import styled from "styled-components"
import { Layout } from "../components/layout"
import { Link } from "gatsby"
import { Seo } from "../components/common"

const ErrorPage = () => {
  return (
    <>
      <Seo title={"404"} />

      <Layout>
        <ErrorPageContainer className="section curved-bcg-svg">
          <div>
            <h1>404</h1>
            <p>Page Not Found :/</p>
            <Link to="/" title="Back To Home" className="btn btn-round">
              Back to home
            </Link>
          </div>
        </ErrorPageContainer>
      </Layout>
    </>
  )
}

const ErrorPageContainer = styled.section`
  display: grid;
  min-height: calc(100vh - 60px);
  place-items: center;
  text-align: center;

  h1 {
    font-size: 5rem;
    color: var(--clr-white);
  }

  .btn-round {
    transform: translateY(2rem);
    background-color: var(--clr-red2);
    padding: 0.5rem 1.5rem;

    &:hover {
      background-color: var(--clr-red);
    }
  }
`

export default ErrorPage
